body {
  background-color: white;
}

.header{
  background-color: #39d4e1;
  width: 100%;
  height: 125px;   
  margin: auto;  
  color: black;
  font-size: 250%;
  text-align: center;
  line-height: 125px;
  font-family: 'Courier New', Courier, monospace;
}


.footer{
  background-color: #39d4e1;
  width: 100%;
  height: 125px;   
  margin: auto;  
  color: black;
  font-size: 250%;
  text-align: center;
  line-height: 125px;
  font-family: 'Courier New', Courier, monospace;
}

a {
  text-decoration: none;
  color: black;
}




