.backButton {
    margin-top: -120px;
    margin-bottom: 67.5px;
    width: 125px;
    height: 125px;
    text-align: left;
    line-height: 200px;
}

.itern-header{
  cursor: pointer;
}

.liLink{
    width: 250px;
    height: 50px;
    background-color: #39d4e1;
    text-align: center;
    border-radius: 70%;
    line-height: 45px;
    font-size: 100%;
    list-style-type: none;
    margin: 1%;
    cursor: pointer;
}

.liLink-active {
    background-color: #8dbcc0;
}

.internItem{
    width: 25%;
    float: left;
    margin: 3%;
    padding: 1%;
}

.competentie {
    width: 58%;
    float: left;
    margin: 3%;
    padding: 1%;
}

.competentie-small-img {
    width: 40% !important;
}

.competentie img{
    border-radius: 0%;
    width: 75%;
}

.internFoto{
    width: 25%;
    float: left;
    margin: 3%;
    padding: 1%;
    text-align: center;
}

.img{
    width: 50%;
}

video {
    width: 50em;
}

@media screen and (max-width: 1200px) {
    .backButton {
        width: 125px;
        margin-top: -125px;
        height: 125px;
        text-align: left;
        line-height: 190px;
    }

    .internItem{
        width: 40%;
    }

    .img{
        width: 40%;
    }
}
  
@media screen and (max-width: 800px) {
    .backButton {
        width: 125px;
        margin-top: -125px;
        height: 125px;
        text-align: left;
        line-height: 175px;
    }

    .internItem{
        width: 90%;
    }

    .img{
        width: 30%;
    }
}