.content{
  width: 100%;
  margin: auto;
  min-height: calc(100vh - 130px);
}

.Pheader{
  text-align: center;
  font-size: 200%;
  color: black;
  font-family: cursive;
  margin: 2%;
}


.uneven {
  width: 97%;
  padding: 1.5%;
  background-color: white;
  display: block;
  overflow: auto;
  min-height: 500px;
}

.uneven img {
  border-radius: 50%;
}


.even {
  width: 97%;
  padding: 1.5%;
  display: block;
  overflow: auto;
  background-color: #fdf4a1;
  min-height: 500px;
}

.even img {
  border-radius: 50%;
}


.itemHeader{
  margin-top: 5%;
  text-align: center;
  font-family: cursive;
  font-size: 200%;
}

.item{
  width: 25%;
  text-align: center;
  float: left;
  margin-bottom: 2%;
  filter: grayscale(100%)
}
.item:hover{
  filter: none;
}

.circle{
  height: 300px;
  width: 300px;
  border-radius: 50%;
  display: inline-block;
  font-size: 200%;
}

.aboutItem{
  width: 30%;
  float: left;
  margin:1.5%;
}

.aboutFoto{
  width: 30%;
  float: left;
  text-align: center;
  margin:1.5%;
}

@media screen and (max-width: 1400px) {
  .item{
    width: 33%;
    text-align: center;
    float: left;
    margin-bottom: 2%;
  }

  .aboutItem {
    width: 40%
  }
}

@media screen and (max-width: 1200px) {
  .item{
    float: left;
    text-align: center;
    width: 50%;
  }

  .itemHeader{
    font-size: 150%;
  }

  .aboutItem {
    margin: 5%;
    width: 90%;
  }

  .aboutFoto {
    margin: 5%;
    width: 90%;
  }
  
}

@media screen and (max-width: 800px) {
  .item{
    float: none;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 10%;
    text-align: center;
    width: 100%;
  }
}