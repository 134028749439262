body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: white;
}

.header{
  background-color: #39d4e1;
  width: 100%;
  height: 125px;   
  margin: auto;  
  color: black;
  font-size: 250%;
  text-align: center;
  line-height: 125px;
  font-family: 'Courier New', Courier, monospace;
}


.footer{
  background-color: #39d4e1;
  width: 100%;
  height: 125px;   
  margin: auto;  
  color: black;
  font-size: 250%;
  text-align: center;
  line-height: 125px;
  font-family: 'Courier New', Courier, monospace;
}

a {
  text-decoration: none;
  color: black;
}





.content{
  width: 100%;
  margin: auto;
  min-height: calc(100vh - 130px);
}

.Pheader{
  text-align: center;
  font-size: 200%;
  color: black;
  font-family: cursive;
  margin: 2%;
}


.uneven {
  width: 97%;
  padding: 1.5%;
  background-color: white;
  display: block;
  overflow: auto;
  min-height: 500px;
}

.uneven img {
  border-radius: 50%;
}


.even {
  width: 97%;
  padding: 1.5%;
  display: block;
  overflow: auto;
  background-color: #fdf4a1;
  min-height: 500px;
}

.even img {
  border-radius: 50%;
}


.itemHeader{
  margin-top: 5%;
  text-align: center;
  font-family: cursive;
  font-size: 200%;
}

.item{
  width: 25%;
  text-align: center;
  float: left;
  margin-bottom: 2%;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%)
}
.item:hover{
  -webkit-filter: none;
          filter: none;
}

.circle{
  height: 300px;
  width: 300px;
  border-radius: 50%;
  display: inline-block;
  font-size: 200%;
}

.aboutItem{
  width: 30%;
  float: left;
  margin:1.5%;
}

.aboutFoto{
  width: 30%;
  float: left;
  text-align: center;
  margin:1.5%;
}

@media screen and (max-width: 1400px) {
  .item{
    width: 33%;
    text-align: center;
    float: left;
    margin-bottom: 2%;
  }

  .aboutItem {
    width: 40%
  }
}

@media screen and (max-width: 1200px) {
  .item{
    float: left;
    text-align: center;
    width: 50%;
  }

  .itemHeader{
    font-size: 150%;
  }

  .aboutItem {
    margin: 5%;
    width: 90%;
  }

  .aboutFoto {
    margin: 5%;
    width: 90%;
  }
  
}

@media screen and (max-width: 800px) {
  .item{
    float: none;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 10%;
    text-align: center;
    width: 100%;
  }
}
.backButton {
    margin-top: -120px;
    margin-bottom: 67.5px;
    width: 125px;
    height: 125px;
    text-align: left;
    line-height: 200px;
}

.itern-header{
  cursor: pointer;
}

.liLink{
    width: 250px;
    height: 50px;
    background-color: #39d4e1;
    text-align: center;
    border-radius: 70%;
    line-height: 45px;
    font-size: 100%;
    list-style-type: none;
    margin: 1%;
    cursor: pointer;
}

.liLink-active {
    background-color: #8dbcc0;
}

.internItem{
    width: 25%;
    float: left;
    margin: 3%;
    padding: 1%;
}

.competentie {
    width: 58%;
    float: left;
    margin: 3%;
    padding: 1%;
}

.competentie-small-img {
    width: 40% !important;
}

.competentie img{
    border-radius: 0%;
    width: 75%;
}

.internFoto{
    width: 25%;
    float: left;
    margin: 3%;
    padding: 1%;
    text-align: center;
}

.img{
    width: 50%;
}

video {
    width: 50em;
}

@media screen and (max-width: 1200px) {
    .backButton {
        width: 125px;
        margin-top: -125px;
        height: 125px;
        text-align: left;
        line-height: 190px;
    }

    .internItem{
        width: 40%;
    }

    .img{
        width: 40%;
    }
}
  
@media screen and (max-width: 800px) {
    .backButton {
        width: 125px;
        margin-top: -125px;
        height: 125px;
        text-align: left;
        line-height: 175px;
    }

    .internItem{
        width: 90%;
    }

    .img{
        width: 30%;
    }
}
.section{
    width: 100%;
    height: auto;
    overflow: auto;
    margin-top: 2%; 
    padding-top: 20px;
    padding-bottom: 10px; 
}

h5{
    font-size: 15px;
}

.contains-a a {
    color: black;
}

.contains-a a:hover {
    color: grey;
}

.blue a {
    color: rgb(0, 0, 255);
}

.blue a:hover {
    color: rgb(83, 83, 226);
}

.contains-a-blue {
    color: rgb(0, 0, 255);
}

.contains-a-blue:hover {
    color: rgb(83, 83, 226);
}
.link {
    cursor: pointer;
    color: rgb(0, 0, 255);
}

.link:hover{
    color: rgb(0, 0, 150);
}
