.section{
    width: 100%;
    height: auto;
    overflow: auto;
    margin-top: 2%; 
    padding-top: 20px;
    padding-bottom: 10px; 
}

h5{
    font-size: 15px;
}

.contains-a a {
    color: black;
}

.contains-a a:hover {
    color: grey;
}

.blue a {
    color: rgb(0, 0, 255);
}

.blue a:hover {
    color: rgb(83, 83, 226);
}

.contains-a-blue {
    color: rgb(0, 0, 255);
}

.contains-a-blue:hover {
    color: rgb(83, 83, 226);
}